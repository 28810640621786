<template>
  <div>
    <section id="contentHeader">
      <h1>Login</h1>
    </section>
    <section id="mainContent" class="w-12 bg-white">
      <h1>You've successfully logged out.</h1>
      <div>
        <router-link class="btn bg-ltblue rounded-sm" to="/" tag="button">RETURN TO HOME</router-link>
        <router-link class="btn bg-ltblue rounded-sm" to="/login" tag="button">LOG IN</router-link>
      </div>
    </section>
    <section id="contentFooter" class="columns"></section>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('setUserStatus', { target: 'authenticated', status: false });
    localStorage.clear();
    this.$router.push('/');
    // this.$store.watch(
    //   (state) => {
    //     return state.user.authenticated;
    //   },
    //   ((newVal, oldVal) => {
    //     if (newVal !== oldVal) {
    //       //   this.$forceUpdate();
    //     }
    //   }).bind(this),
    //   {}
    // );
  },
  watch: {},
  methods: {},
};
</script>